import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer_container section_padding">
      <p>matstel | Strona całkowicie wykonana w technologii React.js </p>
    </div>
  );
};

export default Footer;
