import React from 'react'
import "./Header.css"
import { HeaderTextSlider } from '../../components'
import { HeaderPhoto } from '../../assets'


const Header = () => {
  return (
    <div className='header__container section_padding'>
      <div className='header__text-slider'>
      <HeaderTextSlider/>
      </div>
    <div className='header__photo-section'>
      <div className='header__photo-img'>
      
        <img src={HeaderPhoto} alt="Mateusz Stelmaszczyk"/>
      </div>
      
    </div>
    </div>
  )
}

export default Header