import React, { useState } from 'react'
import { ProductRetouch, SocialMedia, Websites } from '../../libs'
import "./LastestProjectGalleryModule.css"
import {AiFillCloseCircle} from 'react-icons/ai'



const LastestProjectGalleryModule = (props) => {

const [divSelecte, setDivSelecte] = useState(NaN)

  return (
    <div className='lastestproject_gallery-section-module'>
        <div className='lastestproject_gallery-section-module-img'>
        <div className='lastestproject_gallery-section-module-blur'></div>
      <img className='lastestproject_gallery-section-module-img-img' src={props.image} alt={props.title} />
     
      <div className='lastestproject_gallery-section-module-showMore' onClick={
      ()=> {        
         
        setDivSelecte(props.galleryid)
        props.showGaleryItem(true)
        console.log("useState => ", divSelecte, props.galeryItem)
        console.log("Div kliknięty => ", props.galleryid )
  

}
 
  
        
      }>
    
      <p>zobacz więcej</p>
      </div>

      </div>
{

  // SOCIAL MEDIA 
     props.galeryItem && props.lastestProjectsMenuItem === 0 && SocialMedia.map(
      (data,index) => {
        
        if (index === divSelecte) {
          return (
            <>
            <div className='lastestproject_gallery_showMore-container'>
              <div className='lastestproject_gallery_showMore-box'>
                <div className='lastestproject_gallery_showMore-headSection'>
                <div className='lastestproject_gallery_showMore-headSection-title'>
                  <h4>{data.title}</h4>
                </div>
                <div className='lastestproject_gallery_showMore-headSection-button'>
                <AiFillCloseCircle className='closeIcon'onClick={
                     ()=> {
                           props.showGaleryItem(false)
                           setDivSelecte()
                          }
                          } />
           
                </div>
          
                </div>
                <div className='lastestproject_gallery_showMore-paragaphSection'>
                  <div className='lastestproject_gallery_showMore-paragaphSection-image'>
                    <img className='lastestproject_gallery_showMore-paragaphSection-image-img' src={data.imageMore ? data.imageMore : data.image} alt={data.title} />
                  </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text'>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-technologyIcons'>
                      <p>Zastowoana technologia: </p>
                      <p><span>{data.technologia}</span></p>


                   </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-text'>
                     <p>{props.lastestProjectsMenuItem === 0 && data.imageMaking ? "Grafika została wykonana z następujących elementów:" : ""}</p>
                    </div>
                  <div className='lastestproject_gallery_showMore-paragaphSection-text-img'>
                   { 
                   
                    data.imageMaking ? 
                     data.imageMaking.map(
                     (image,index)=> {
                      return (
                         
                         <div key={index} className='lastestproject_gallery_showMore-paragaphSection-text-imgContainer'>
                         <img className='lastestproject_gallery_showMore-paragaphSection-text-img-img' src={image} />
                         </div>
                      )
                     }

                   )
                   :
                   ""
                   
                   }
                    
                    </div>
                  
                 </div>
                </div>
            </div>
            </div>
            </>
          )
        }
      }
     )




}

{/* STRONY WWW */}
{
     props.galeryItem && props.lastestProjectsMenuItem === 1 && Websites.map(
      (data,index) => {
        
        if (index === divSelecte) {
          return (
            <>
            <div className='lastestproject_gallery_showMore-container'>
              <div className='lastestproject_gallery_showMore-box'>
                <div className='lastestproject_gallery_showMore-headSection'>
                <div className='lastestproject_gallery_showMore-headSection-title'>
                  <h4>{data.title}</h4>
                </div>
                <div className='lastestproject_gallery_showMore-headSection-button'>
                <AiFillCloseCircle className='closeIcon'onClick={
                     ()=> {
                           props.showGaleryItem(false)
                           setDivSelecte()
                          }
                          } />
           
                </div>
          
                </div>
                <div className='lastestproject_gallery_showMore-paragaphSection'>
                  <div className='lastestproject_gallery_showMore-paragaphSection-image'>
                    <img className='lastestproject_gallery_showMore-paragaphSection-image-img' src={data.imageMore ? data.imageMore : data.image} alt={data.title} />
                  </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text'>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-technologyIcons'>
                      <p>Zastowoana technologia: </p>
                      <p><span>{data.technologia}</span></p>


                   </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-text'>
                     <p>{props.lastestProjectsMenuItem === 0 && data.imageMaking ? "Grafika została wykonana z następujących elementów:" : ""}</p>
                    </div>
                  <div className='lastestproject_gallery_showMore-paragaphSection-text-img'>
                   { 
                   
                    data.imageMaking ? 
                     data.imageMaking.map(
                     (image,index)=> {
                      return (
                         
                         <div key={index} className='lastestproject_gallery_showMore-paragaphSection-text-imgContainer'>
                         <img className='lastestproject_gallery_showMore-paragaphSection-text-img-img' src={image} />
                         </div>
                      )
                     }

                   )
                   :
                   ""
                   
                   }
                    
                    </div>
                  
                 </div>
                </div>
            </div>
            </div>
            </>
          )
        }
      }
     )




}

{/* RETUSZ ZDJĘĆ */}
{
     props.galeryItem && props.lastestProjectsMenuItem === 2 && ProductRetouch.map(
      (data,index) => {
        
        if (index === divSelecte) {
          return (
            <>
            <div className='lastestproject_gallery_showMore-container'>
              <div className='lastestproject_gallery_showMore-box'>
                <div className='lastestproject_gallery_showMore-headSection'>
                <div className='lastestproject_gallery_showMore-headSection-title'>
                  <h4>{data.title}</h4>
                </div>
                <div className='lastestproject_gallery_showMore-headSection-button'>
                <AiFillCloseCircle className='closeIcon'onClick={
                     ()=> {
                           props.showGaleryItem(false)
                           setDivSelecte()
                          }
                          } />
           
                </div>
          
                </div>
                <div className='lastestproject_gallery_showMore-paragaphSection'>
                  <div className='lastestproject_gallery_showMore-paragaphSection-image'>
                    <p style={{fontWeight:"bold",fontSize:"22px"}}>Przed Retuszu:</p>
                    <img className='lastestproject_gallery_showMore-paragaphSection-image-img' src={data.imageBefore} alt={data.title} />
                    <p style={{fontWeight:"bold",fontSize:"22px"}}>Po Retuszu:</p>
                    <img className='lastestproject_gallery_showMore-paragaphSection-image-img' src={data.imageMore ? data.imageMore : data.image} alt={data.title} />
                  </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text'>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-technologyIcons'>
                      <p>Zastowoana technologia: </p>
                      <p><span>{data.technologia}</span></p>


                   </div>
                 <div className='lastestproject_gallery_showMore-paragaphSection-text-text'>
                     <p>{props.lastestProjectsMenuItem === 0 && data.imageMaking ? "Grafika została wykonana z następujących elementów:" : ""}</p>
                    </div>
                  <div className='lastestproject_gallery_showMore-paragaphSection-text-img'>
                   { 
                   
                    data.imageMaking ? 
                     data.imageMaking.map(
                     (image,index)=> {
                      return (
                         
                         <div key={index} className='lastestproject_gallery_showMore-paragaphSection-text-imgContainer'>
                         <img className='lastestproject_gallery_showMore-paragaphSection-text-img-img' src={image} />
                         </div>
                      )
                     }

                   )
                   :
                   ""
                   
                   }
                    
                    </div>
                  
                 </div>
                </div>
            </div>
            </div>
            </>
          )
        }
      }
     )




}

    </div>
  )
}

export default LastestProjectGalleryModule