import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-8JMJ3DXYKQ";

ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });
// ReactGA.pageview(window.location.pathname + window.location.search);
// console.log(
//   ReactGA.pageview(window.location.pathname + window.location.search)
// );

// ReactGA.initialize([
//   {
//     trackingId: "G-8JMJ3DXYKQ"
//   }
// ]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
