import {SiAdobephotoshop, SiAdobeillustrator} from 'react-icons/si'
import { 
   PostPerfumy, 
   PostLodowka,PostPralka,
   PostPerumyMore,
   ZczegoZrobione_PostPerfumy,
   Fotomontaz01, 
   Manipulacja02, 
   Manipulacja03, 
   Manipulacja04, 
   Manipulacja05, 
   Manipulacja06, 
   Manipulacja07, 
   Manipulacja08, 
   Manipulacja09,
   Manipulacja01,
   LotnikGameDayMaking01,
   LotnikGameDayMaking02,
   LotnikGameDay, 



} from '../../../assets'

const SocialMedia = [
   {
      title:"Post Produktowy - Perfumy",
      text:"Text opisowy",
      image:PostPerfumy,
      imageMore:PostPerumyMore,
      imageMaking:[ZczegoZrobione_PostPerfumy],
      technologia:<SiAdobephotoshop/>,
    
    },
    {
      title:"Fotomanipulacja - Dziewczyna",
      text:"Text Opisowy",
      image:Fotomontaz01,
      imageMaking:[Manipulacja01, Manipulacja02, Manipulacja03, Manipulacja04, Manipulacja05, Manipulacja06, Manipulacja07, Manipulacja08, Manipulacja09],
      technologia:<SiAdobephotoshop/>
    },
    {
      title:"Akademia Lotnik Kościelec - Game Day",
      text:"Text opisowy",
      image:LotnikGameDay,
      imageMaking:[LotnikGameDayMaking01,LotnikGameDayMaking02],
      technologia:<SiAdobephotoshop/>
    },
    {
      title:"Post Produktowy - Lodówka",
      text:"Text Opisowy",
      image:PostLodowka
    }
]

export default SocialMedia