import React, {useRef} from 'react'
import "./Contact.css"
import emailjs from '@emailjs/browser'
import {Formik, Field, Form, useField, ErrorMessage} from "formik"
import * as Yup from "yup"


const Contact = ({props}) => {
  
const SendEmail  = (object) =>  {
    emailjs.send("service_2csz8tj", "template_4pckw12", object,"YrILGE4XzgFDp-tKo" )
        .then((result) => {
            console.log(result.text)
        }, (error) => {
            console.log(error.text)
        })
}




  return (
    <div id="contact" className='contact_container section_padding'>
    <div className='section_head-title'>
      <h2>Kontakt</h2>
    </div>



    <div className='formik__wrapper'>
<Formik 
initialValues={{
  nameAndsurname:"",
  email:"",
  phone:"",
  subject:"",
  textarea:""

}}
validationSchema={Yup.object({
  nameAndsurname:Yup.string()
  .min(4, "Imię i naziwsko musi posiadać minimum 4 znaki")
  .max(30, "Imię i nazwisko musi posiadać maksimum 30 znaków")
  .required("Pole nie może być puste"),
  email:Yup.string()
  .email("Błędny format e-mail")
  .min(4,"E-mail musi posiadać minimum 4 znaki")
  .max(30, "E-mail musi posiadać maksimum 30 znaków")
  .required("Pole nie może być puste"),
  phone:Yup.number()
  .positive()
  .integer()
  // .string()
  // .min(4, "Telefon musi posiadać minimum 4 znaki")
  // .max(12, "Telefon musi posiadać maksimum 12 znaków")
  .typeError('Pole telefon może zawierać tylko cyfry')
  .required("Pole nie może być puste")
 ,
  subject:Yup.string()
  .min(4, "Temat musi posiadać minimum 4 znaki")
  .max(30, "Temat musi posiadać maksimum 30 znaków")
  .required("Pole nie może być puste"),
  textarea:Yup.string()
  .min(30, "Treść wiadomości musi posiadać minimum 30 znaków")
  .required("Pole nie może być puste")
}

)
}

onSubmit={(values, actions) => {
  setTimeout(() => {
    SendEmail(values)
    actions.setSubmitting(false)
  }, 1000)
}}
>
       <Form className='formik__container' >
   
      <Field className="formik__field" name="nameAndsurname" id="nameAndsurname" placeholder="Imię i nazwisko"/>    
      <ErrorMessage name="nameAndsurname" component='label' className='errorMessage__label slide-bottom'/>
      <Field className="formik__field" name="email" id="email" placeholder="Adres e-mail"/>    
      <ErrorMessage name="email" component='label' className='errorMessage__label slide-bottom'/>
      <Field className="formik__field" name="phone" id="phone" placeholder="Nr telefonu"/>    
      <ErrorMessage name="phone" component='label' className='errorMessage__label slide-bottom'/>
      <Field className="formik__field" name="subject" id="subject" placeholder="Temat"/>    
      <ErrorMessage name="subject" component='label' className='errorMessage__label slide-bottom'/>
      <Field className="formik__field" name="textarea" id="textarea" as="textarea" control="textarea" placeholder="Treść wiadomości. . ."/>    
      <ErrorMessage name="textarea" component='label' className='errorMessage__label slide-bottom'/>

      <button className='formik_button' type='submit'>Wyślij</button>
    </Form>
    </Formik>
    </div>
    </div>
   
  )
}

export default Contact