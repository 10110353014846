import { React, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import "./App.css";
import { inView, useInView } from "react-intersection-observer";

import {
  About,
  Contact,
  Footer,
  Header,
  LastestProjects,
  Offer,
  PriceList
} from "./containers";
import { Nav } from "./components";

const App = () => {
  return (
    <div>
      <Nav />

      <Header />
      <About />
      <Offer />
      <LastestProjects />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
