import {SiAdobephotoshop} from 'react-icons/si'
import { Retusz01After,Retusz01Before,Retusz02After,Retusz02Before,Retusz03After,Retusz03Before } from '../../../assets'

const ProductRetouch = [
    {
        title:"Retusz Produktowy",
        text:"Text opisowy",
        image:Retusz01After,
        imageBefore:Retusz01Before,
        technologia:<SiAdobephotoshop/>,
      
    },
    {
        title:"Retusz Produktowy",
        text:"Text opisowy",
        image:Retusz02After,
        imageBefore:Retusz02Before,
        technologia:<SiAdobephotoshop/>,
      
    },
    {
        title:"Retusz Produktowy",
        text:"Text opisowy",
        image:Retusz03After,
        imageBefore:Retusz03Before,
        technologia:<SiAdobephotoshop/>,
      
    },
]

export default ProductRetouch