
import React, {useState} from 'react'
import "./OfferPanel.css"
import {OfferPanelReadMore } from '../../components'
import { OfferTexts } from '../../libs/'


const OfferPanel = (props) => {


const [buttonIdForReadMore, setButtonIdForReadMore] = useState(props.buttonofferid)
const [readMore, showreadMore] = useState(false)


  return (
    
    <div className='offer_component'>
    <div className='offer_component-headSection'>
      <div className='offer_component-headSection-icon-img'>
         <img src={props.icon} alt="social media" />
      </div>
      <div className='offer_component-headSection-title'>
        <h4>{props.title}</h4>
      </div>
      </div>
      <div className='offer_component-middleSection-paragraph'>
        <p>{props.txt}
        </p>
      </div>
      <div className='offer_component-buttonSection'>
        <button buttonofferid={props.buttonofferid} buttonidforreadmore={buttonIdForReadMore} onClick={()=> 
        
        {
     
        setButtonIdForReadMore(props.buttonofferid)
        showreadMore(true)
       
      } 
    
        } >czytaj więcej...</button>
        
 
      </div>
     

     {
      
      readMore  && OfferTexts.map(
        (data,index) => {
          if ( index === buttonIdForReadMore) {
        
          return (  
          <OfferPanelReadMore
          key={'stronaoferty'+index}
          showreadMore={showreadMore}
          title={data.title}
          opis={data.opis}
          iconParagraph={data.iconParagraph}
          icons={data.icons}
         
          
          
          />)
        }
          }

      ) 
     
}
    
     
  </div>
 
  
  )
}

export default OfferPanel