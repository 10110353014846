import React, { useState } from "react";
import Offer from "../../containers/Offer/Offer";
import ScrollIntoView from "react-scroll-into-view";

import "./Nav.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Menu = () => (
  <>
    <p>
      <a href="#">Strona Główna</a>
    </p>
    <ScrollIntoView selector="#about">
      <p>
        <a href="#about">O mnie</a>
      </p>
    </ScrollIntoView>
    <ScrollIntoView selector="#offer">
      <p>
        <a href="#offer">Umiejętności</a>
      </p>
    </ScrollIntoView>
    <ScrollIntoView selector="#lastestprojects">
      <p>
        <a href="#lastestProjects">Ostatnie Projekty</a>
      </p>
    </ScrollIntoView>
    {/* <ScrollIntoView selector='#pricelist'> */}
    {/* <p><a href="#priceList">Cennik</a></p> */}
    {/* </ScrollIntoView> */}
    <ScrollIntoView selector="#contact">
      <p>
        <a href="#contact">Kontakt</a>
      </p>
    </ScrollIntoView>
  </>
);

const Nav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navigation-container">
      <div className="naviation-dekstop-container">
        <div className="navigation-logo">
          <p>
            mat<span>stel</span>
          </p>
        </div>
        <div className="navigation-menu">
          <Menu />
        </div>
        <div className="menu-mobile-icon">
          {toggleMenu ? (
            <AiOutlineClose onClick={() => setToggleMenu(false)} />
          ) : (
            <AiOutlineMenu onClick={() => setToggleMenu(true)} />
          )}
        </div>
      </div>
      {toggleMenu && (
        <div className="menu-mobile-bar scale-up-center">
          <Menu />
        </div>
      )}
    </div>
  );
};

export default Nav;
