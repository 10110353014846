import {FaReact} from 'react-icons/fa6'
import { WWW01,WWW02,WWW03,WWW04,WWW01FULL,WWW02FULL,WWW03FULL,WWW04FULL  } from '../../../assets'


const Websites = [
    {
        title:"Strona Internetowa dla Firmy Budowlanej",
        text:"Text opisowy",
        image:WWW01,
        imageMore:WWW01FULL,
        technologia:<FaReact/>,
      
    },
    {
        title:"Strona Internetowa dla Siłowni",
        text:"Text opisowy",
        image:WWW02,
        imageMore:WWW02FULL,
        technologia:<FaReact/>,
      
    },
    {
        title:"Strona Internetowa dla Parafi",
        text:"Text opisowy",
        image:WWW03,
        imageMore:WWW03FULL,
        technologia:<FaReact/>,
      
    },
    {
        title:"Strona Internetowa dla Restauracji",
        text:"Text opisowy",
        image:WWW04,
        imageMore:WWW04FULL,
        technologia:<FaReact/>,
      
    },
]

export default Websites