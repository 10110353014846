import { React,  useState, useEffect } from 'react'
import { useAnimation, motion, easeIn, easeInOut } from 'framer-motion'
import { inView,useInView } from 'react-intersection-observer'
import "./About.css"
import { AboutPhoto } from '../../assets'
import { AboutTextSlide } from '../../components'
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io"

const aboutTexts = [{
  title:"Front - End Developer",
  text:`Jestem doświadczonym front-end developerem, gotowym podjąć się każdego projektu z zapałem i pełnym zaangażowaniem.

  Tworzenie stron internetowych to dla mnie pasja, która pozwala mi łączyć techniczne umiejętności z kreatywnością. Każdy projekt to nowe wyzwanie, a każdy kod to krok w kierunku budowania lepszych i bardziej angażujących witryn online.`
},{
  title:"Grafik Komputerowy",
  text:`Jestem doświadczonym grafikiem komputerowym, zaangażowanym w tworzenie projektów z pasją i kreatywnością. Moja podróż w dziedzinie projektowania rozpoczęła się dawno temu, a od tamtej pory nieustannie doskonalę swoje umiejętności.
  Dla mnie grafika to nie tylko estetyka, ale również sposób przekazywania emocji i wartości. Moje projekty są starannie przemyślane, oddające indywidualność każdego klienta. Za każdym projektem kryje się moje zaangażowanie w odkrywanie nowych perspektyw i źródeł inspiracji.`
}]



const About = () => {

  const photoAnimation = useAnimation()
  const textAnimation = useAnimation()

  const [sliderText] = useState(aboutTexts)
  const [sliderIndex, setSliderIndex] = useState(0)
  let sliderPosition = 'nextSlide'
  let buttonActiveColor = 'red'

  useEffect(
    ()=> {
      const lastIndex = sliderText.length -1
      if (sliderIndex < 0) {
        setSliderIndex(lastIndex)
      }
      if (lastIndex < sliderIndex) {
        setSliderIndex(0)
      }
    },
    [sliderIndex, sliderText]
  )

  useEffect(
    ()=> {
    let slider = setInterval(
      ()=> {
        setSliderIndex(sliderIndex+1)
        // console.log("index slidera: ",sliderIndex)
      }, 5000
    );
    return ()=> {
      clearInterval(slider)
    }
    },
    [sliderIndex]
  )

 
  const {ref, inView} = useInView();
  useEffect(()=> {
if (inView) {
  photoAnimation.start({
    x:0,
    scale:1,
    transition:{
      delay:0,
      ease:'easeIn',
      duration:.7
    }
  })
  textAnimation.start({
    opacity:1,
    transition:{
      delay:.4,
      ease:'easeIn',
      duration:.3
    }
  })

  // console.log("Wejście", inView)
}
if (!inView) {
  photoAnimation.start({
    // x:"-30vw",
    scale:0,
    transition:{
      delay:.3,
      ease:easeInOut,
      duration:.7,
      
    }
  })
  textAnimation.start({
    opacity:0,
    transition:{
      delay:0,
      ease:easeIn,
      duration:.5
    }
  })
 
  // console.log("Wyjście", inView)
}
  })

  return (
   
    <div id="about" className='about__container section_padding'>
     <div ref={ref} className='about__photo-section'>
      <motion.div animate={photoAnimation} transition={{ type: "spring", stiffness: 100 }}>
      <div className="about__photo-section-img">
        <img src={AboutPhoto} alt="Photo About" />
      </div>
      </motion.div>
     </div>
     <div className='about__text-section'>
      <motion.div animate={textAnimation}>
      <div className='about__text-section-component'>
        <h2>O <span>Mnie</span></h2>
        {
          sliderText.map(
            (data,index)=>
           {

            const {text, title} = data
            
           
       
            if (sliderIndex === index) {
              sliderPosition ="about__slider-activeSlide"
         
            }
            if ( sliderIndex === index - 1 || index === 0  &&  sliderIndex === sliderText.length -1)
            {
             sliderPosition = "about__slider-deactiveSlide"
        
             
            }
      
            return (
              <>
              <div className={`about__text-section-component-article ${sliderPosition}`} key={index}>

              <h3>{title}</h3>
              <div className='about__text-sectrion-paragraphBox'>
                <p className='about__text-section-component-parahraph'>
                 {text}
               </p>
               </div>
               <div className='about__text-section-button-controls' >
              <p><IoIosArrowBack className='about_slider-arrowButton' key='A2' onClick={
                ()=> {
                  setSliderIndex(0)
                }
              }/>
              <span className='about_slider-indexSpan' key={'spankey'+index}>{sliderIndex+1}</span>
              <IoIosArrowForward className='about_slider-arrowButton' key='A1'
              onClick={
                ()=> {
                  setSliderIndex(1)
                }
              }
              /></p>
    
       
        </div>
            </div>
        
            </>
            )
           }
          )
        }


  
   
      </div>
      </motion.div>
      
     </div>
    </div>
    
  )
}

export default About