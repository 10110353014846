import {BiLogoReact, BiLogoJavascript, BiLogoCss3,BiLogoHtml5} from 'react-icons/bi'
import {SiAdobephotoshop, SiAdobeillustrator} from 'react-icons/si'


const OfferTexts = [
  {
    title: 'Projektowanie grafik na potrzeby social mediów',
    opis: [
      <p>Tworzę unikalne grafiki, które przyciągną uwagę, wyróżnią Cię oraz zachęcą do interakcji. Jako freelancer specjalizujący się w tworzeniu przekonujących grafik na potrzeby mediów społecznościowych, jestem gotów zamienić Twoje pomysły w wizualne arcydzieła.</p>,
      <p>W dzisiejszym dynamicznym świecie cyfrowym kluczowe jest skuteczne komunikowanie przekazu Twojej marki – a to właśnie moja specjalność. Dzięki wyczuciu designu i głębokiemu zrozumieniu mechanizmów mediów społecznościowych, tworzę grafiki, które nie tylko przyciągają wzrok, lecz także rezonują z Twoją docelową grupą odbiorców.</p>,
      <p style={{fontWeight:'bold'}}>Co mnie wyróżnia?</p>,
      <p>Dopasowanie do Twojej Marki: Twoja marka jest wyjątkowa, a Twoje grafiki powinny to odzwierciedlać. Wnikliwie zgłębiam osobowość, ton i cele Twojej marki, by każda grafika idealnie współgrała z Twoją tożsamością.</p>,
      <p>Spójność na Wszystkich Platformach: Niezależnie od tego, czy to Facebook, Instagram czy platforma E-Commerce, rozumiem niuanse każdej platformy i tworzę grafiki optymalizowane pod jej indywidualne wymagania</p>
    ],
    iconParagraph:<p>Używane technologie:</p>,
    icons:[<SiAdobephotoshop/>, <SiAdobeillustrator/>]
    
   
  },
  {
    title: 'Strony WWW',
    opis: [
      <p>Posiadam umiejętności w obszarach takich jak HTML, CSS oraz JavaScript, a także doświadczenie w wykorzystaniu zaawansowanych narzędzi, w tym React, do tworzenia interaktywnych elementów na stronach. Moje działania opierają się na precyzyjnym kodowaniu, dbałości o szczegóły oraz zapewnianiu spójności między różnymi elementami witryny.</p>,
      <p>Jako twórca stron internetowych, moim celem jest nie tylko osiągnięcie atrakcyjnego wyglądu, ale także zapewnienie płynnej i intuicyjnej nawigacji dla użytkowników. Pracuję nad responsywnymi projektami, które dostosowują się do różnych urządzeń i rozmiarów ekranów, aby zapewnić spójne wrażenia na każdym medium.</p>,
      <p>Moja praca opiera się na współpracy z klientami, aby zrozumieć ich cele biznesowe i estetyczne preferencje. Dążę do stworzenia stron internetowych, które nie tylko oddają tożsamość marki, ale także efektywnie komunikują przekaz oraz przyciągają uwagę grupy docelowej.</p>,
      <p>Codziennie poszerzam swoją wiedzę i uczę się nowych technologii, aby zapewnić, że moje projekty są zgodne z najnowszymi trendami i standardami. Moje podejście jest oparte na dokładności, systematyczności i zdolności do rozwiązywania problemów, co przekłada się na witryny internetowe, które są nie tylko estetyczne, ale także funkcjonalne i dostosowane do wymagań współczesnego świata online.</p>,
    ],
    iconParagraph:<p>Używane technologie:</p>,    
    
    icons:[
      <BiLogoReact/>,
      <BiLogoJavascript/>,
      <BiLogoCss3/>,
      <BiLogoHtml5/>
    ]
  },
  {
    title: 'Retusz Zdjęć',
    opis:[
      <p>Specjalizuję się w retuszu produktowym, dbając o to, żeby Twoje produkty prezentowały się perfekcyjnie. </p>,
      <p>Moja praca polega na usuwaniu niedoskonałości, dostosowywaniu kolorów i poprawianiu detali, by stworzyć wizerunek produktów zachęcający do zakupu. </p>,
      <p>Wykorzystuję zaawansowane narzędzia i techniki, by podkreślić najlepsze cechy każdego produktu, a także dopasować zdjęcia do standardów wyznaczonych przez rynek. </p>,
      <p>Niezależnie od tego, czy potrzebujesz retuszu dla e-sklepu, na potrzeby marketplace, katalogu czy kampanii marketingowej, moje umiejętności pozwolą Ci osiągnąć doskonały efekt wizualny i wyróżnić się na konkurencyjnym rynku.</p>,
    ],
    iconParagraph:<p>Używane technologie:</p>,
    icons:<SiAdobephotoshop/>
  }
]

export default OfferTexts