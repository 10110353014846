import React, { useRef, useState } from 'react'
import "./LastestProjects.css"
import {LastestProjectGalleryModule} from '../../components'
import { ProductRetouch, SocialMedia,Websites } from '../../libs'


const Menu = [
  {
  title:"Prace Graficzne",
  href:"#socialMedia",
  },
  {
    title:"Strony WWW",
    href:"#stronywww",
  },
  {
    title:"Retusz Zdjęć",
    href:"#retusz",
  }
]






const LastestProjects = (props) => {
  
  const [lastestProjectsMenuItem, setlastestProjectsMenuItem] = useState(0)
  const [galeryItem, showGaleryItem] = useState(false)



  return (
    <div id="lastestprojects" className='lastestprojects__container section_padding'>
      <div className='section_head-title'>
        <h2>Ostatnie<span> Projekty</span></h2>
      </div>
      <div className='lastestprojects__head-navigation'> 
     {
     Menu.map(
      (data,key) =>{
        return (
          <>
          <p role="button" key={key} ><a href={data.href} style={lastestProjectsMenuItem === key ? {color:"var(--color-subtext)"}:{}}  onClick={
            ()=> {
              console.log("Menu Index: ",lastestProjectsMenuItem)   
              setlastestProjectsMenuItem(key)
            }
            
                      
          } >{data.title}</a></p>
          </>
        )
      }
     )
     }
      </div>
      <div className='lastestprojects__gallery-section-container'>
      {
        lastestProjectsMenuItem === 0 && SocialMedia.map(
          (index,key) =>
          
          (
            <LastestProjectGalleryModule 
            title={index.title}
            galleryid={key} 
            image={index.image} 
            key={key}
            galeryItem={galeryItem}
            showGaleryItem={showGaleryItem}
            lastestProjectsMenuItem={lastestProjectsMenuItem}
                 />
          )
         )

         
       
      }
      {
        lastestProjectsMenuItem === 1 && Websites.map(
          (index,key)=> 
            (
              <LastestProjectGalleryModule 
              title={index.title}
              galleryid={key} 
              image={index.image} 
              key={key}
              galeryItem={galeryItem}
              showGaleryItem={showGaleryItem}
              lastestProjectsMenuItem={lastestProjectsMenuItem}
                   />
            )
          
        )
      }

{
        lastestProjectsMenuItem === 2 && ProductRetouch.map(
          (index,key)=> 
            (
              <LastestProjectGalleryModule 
              title={index.title}
              galleryid={key} 
              image={index.image} 
              key={key}
              galeryItem={galeryItem}
              showGaleryItem={showGaleryItem}
              lastestProjectsMenuItem={lastestProjectsMenuItem}
                   />
            )
          
        )
      }
      </div>

      </div>
  )
}

export default LastestProjects