import React from 'react'
import "./OfferPanelReadMore.css"
import {AiFillCloseCircle} from 'react-icons/ai'



const OfferPanelReadMore = (props) => {

  return (

    <div className='OfferPanelReadMore__wrapper'>
      
       <div className='OfferPanelReadMore__container'>
       <div className='OfferPanelReadMore_head-section-icon'>
           <AiFillCloseCircle className='closeIcon'  onClick={
            ()=> {
            
               props.showreadMore(false)
            
            }
          }
            /> 
            </div>
         <div className='OfferPanelReadMore_head-section'>
          
             <div className='OfferPanelReadMore_head-section-title OfferPanel-padding'>
              <h3> {props.title}</h3>
             </div>
         </div>
         <div className='OfferPanelReadMore_paragraph-section OfferPanel-padding'>
          <div className='OfferPanelReadMore_paragraph-section-text '>
          <>
          {props.opis}
          </>
          </div>
          <p> {props.iconParagraph}</p>
          <div className='OfferPanelReadMore_paragraph-section-icons'>
           
           {props.icons}
            
          </div>
         </div>
       </div>
     
    </div>
    
  )
}

export default OfferPanelReadMore