import React, { useState } from "react";
import "./Offer.css";
import { OfferPanel, OfferPanelReadMore } from "../../components";
import { OfferSocialMedia, OfferWebsites, OfferRetouch } from "../../assets";
import { AiFillCloseCircle } from "react-icons/ai";

const offerData = [
  {
    icon: OfferSocialMedia,
    title: "Social Media",
    txt: `Projektowanie grafik 
  na potrzeby social mediów. 
  Skupiam się na tworzeniu treści wizualnych, które wyróżniają Twoją markę i zwiększają zaangażowanie użytkowników`
  },
  {
    icon: OfferWebsites,
    title: "Strony WWW",
    txt: `Odkrywam świat poprzez kod i kreuję unikalne przestrzenie online - jestem twórcą stron internetowych, który z pasją zamienia pomysły w rzeczywistość!`
  },

  {
    icon: OfferRetouch,
    title: "Retusz Zdjęć",
    txt: `Specjalizuję się w retuszu produktowym. Moja praca polega na usuwaniu niedoskonałości, dostosowywaniu kolorów i poprawianiu detali, by stworzyć wizerunek produktów zachęcający do zakupu`
  }
];

const Offer = (props) => {
  return (
    <div id="offer" className="offer__container  section_padding">
      <div className="section_head-title">
        <h2>
          <a href="#oferta">Umiejętności</a>
        </h2>
      </div>
      <div className="offer_component-container">
        {offerData.map((index, key) => {
          return (
            <OfferPanel
              icon={index.icon}
              title={index.title}
              txt={index.txt}
              buttonofferid={key}
              key={key}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Offer;
