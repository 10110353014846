import React from 'react'
import "./HeaderTextSlider.css"
import { TypeAnimation } from 'react-type-animation'

const HeaderTextSlider = () => {



  return (
    <div className='HeaderTextSlider__container'>
      <div className='HeadterTextSlider__text-h3'>
        <h3>Hej, nazywam się</h3>
      </div>
      <div className='HeaderTextSlider__text-h1'>
        <h1>Mateusz Stelmaszczyk</h1>
      </div>
      <div className='HeaderTextSlider__text-slider'>
        <p>Jestem <span>
        <TypeAnimation
          sequence={['Front End-Developerem', 500, 'Grafikiem', 500]}
          
          repeat={Infinity}
/>
          
          </span> </p>
      </div>
      <div className='HeaderTextSlider__text-article'>
        <p>Moja pasja to tworzenie estetycznych i funkcjonalnych projektów,<br/>
        uwielbiam przekształcać wizje w rzeczywistość</p>
      </div>
    </div>
  )
}

export default HeaderTextSlider